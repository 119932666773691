<template lang="pug">
  v-footer#core-footer(app color='primary'  absolute)
    v-layout(row wrap align-center)
      v-flex(xs12='')
        .ml-4
          | 2020
          v-icon.mx-2(size="small" ) far fa-copyright
          //- v-icon.white--text fa
          | {{description}}
</template>
<script>
import { mapMutations, mapState } from 'vuex'
export default {
  computed: { ...mapState('app', ['name', 'description']) }
}
</script>

<style>
#core-footer {
  z-index: 0;
}
</style>
